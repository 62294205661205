<template>
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12" v-if="loading">
          <div class="row mt-5 pt-5">
            <div class="col-md-12" v-loading="loading"></div>
          </div>
        </div>
        <div class="col-md-12 finance-overview" v-else>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-8">
                  <div
                    class="finance__graph finance_graph_card"
                    v-loading="dataChanging"
                  >
                    <div class="header">
                      <div class="__header__inner">
                        <h1>Finance overview</h1>
                      </div>
                    </div>
                    <div class="body p-3" v-if="graphData.length">
                      <AreaChart
                        :graphData="graphData"
                        :graphLabels="graphLabels"
                        :current-country="selected_country"
                        :key="graphData.length"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="finance_amount_today finance_graph_card">
                    <div class="finance__graph finance_graph_card">
                      <div class="header">
                        <div class="__header__inner today_amount">
                          <el-select
                            size="small"
                            placeholder="Select country"
                            v-model="selected_country"
                            v-if="userCountry == 'GH'"
                            @change="handleChangeCountry"
                          >
                            <el-option
                              label="Ghana"
                              value="GH"
                              v-if="userCountry == 'GH'"
                            ></el-option>
                            <el-option label="CIV" value="CI"></el-option>
                          </el-select>
                          <el-divider direction="vertical"></el-divider>
                          <button class="today_btn">Today</button>
                        </div>
                      </div>
                      <div class="body">
                        <div class="__container_text amount__">
                          <h2>{{ getCurrency }}: {{ amountToday }}</h2>
                          <p>Earn Today</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- BUNDLE HISTORY -->
            <div class="col-md-12">
              <div class="finance_graph_card bundle_history">
                <div class="header">
                  <div class="__header__inner d-flex justify-content-between">
                    <h1>Bundle History</h1>
                    <button
                      @click="
                        $router
                          .push('/admin/finance/bundle_history')
                          .catch((err) => {})
                      "
                    >
                      View All
                      <img
                        class="ml-2"
                        src="../../../assets/arrow_left_icon.svg"
                        alt="img"
                      />
                    </button>
                  </div>
                </div>
                <div class="body bundle_history">
                  <div class="row">
                    <div class="col-md-12">
                      <el-table
                        :data="tableData"
                        :header-cell-style="{
                          background: 'rgba(212, 216, 226, 0.2)',
                        }"
                        style="width: 100%"
                        :empty-text="'No Data Available'"
                        height="55vh"
                      >
                        <el-table-column label="Name" prop="name" width="name">
                        </el-table-column>
                        <el-table-column
                          label="Phone"
                          prop="phone"
                          width="name"
                        >
                        </el-table-column>
                        <el-table-column
                          label="Amount"
                          width="amount"
                          prop="amount"
                        >
                        </el-table-column>
                        <el-table-column
                          label="Bundle Type"
                          width="amount"
                          prop="type"
                        >
                          <template slot-scope="scope">
                            <span
                              v-if="scope.row.type == 1"
                              style="color: #00b4d8"
                              >SMS</span
                            >
                            <span v-else style="color: #f7921c">Voice</span>
                          </template>
                        </el-table-column>
                        <el-table-column
                          label="Balance"
                          prop="balance"
                          width=""
                        >
                        </el-table-column>
                        <el-table-column label="Date" width="">
                          <template slot-scope="scope">
                            {{
                              moment(scope.row.created_at).format(
                                "ll hh:mm:ss A"
                              )
                            }}
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import AreaChart from "./AreaChart.vue";
  import appConfig from "@/app.config";
  import Storage from "../../../services/storage";
  var storage = new Storage();
  export default {
    page: {
      title: "Finance",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { AreaChart },
    data() {
      return {
        loading: false,
        amountToday: "0",
        graphLabels: [],
        graphData: [],
        tableData: [],
        selected_country: "GH",
        dataChanging: false,
      };
    },
    methods: {
      handleChangeCountry() {
        const graphDataArray = [];
        const graphLabelsArray = [];
        this.amountToday = 0;
        this.dataChanging = true;
        store
          .dispatch("admin/reporting/getFinanceGraph", {
            country: this.selected_country,
          })
          .then((res) => {
            let todayDate = new Date().toDateString();
            res.forEach((element) => {
              graphDataArray.push(element.daily_amount);
              graphLabelsArray.push(element.day);

              this.graphData = graphDataArray;
              this.graphLabels = graphLabelsArray;
              if (new Date(element.day).toDateString() == todayDate) {
                this.amountToday = element.daily_amount;
              }
            });
          })
          .finally(() => {
            this.dataChanging = false;
          });

        store
          .dispatch("admin/reporting/getBundleHistory", {
            to: "",
            from: "",
            country: this.selected_country,
          })
          .then((res) => {
            this.tableData = res.splice(0, 10);
          });
      },
    },
    computed: {
      financeGraph() {
        return store.getters["admin/reporting/financeGraph"];
      },
      getCurrency() {
        return this.selected_country === "CI" ? "FCFA" : "GHC";
      },
      userCountry() {
        return storage.getSavedState("auth.admin").country;
      },
    },
    mounted() {
      this.loading = true;
      const userCountry = storage.getSavedState("auth.admin").country;
      if (userCountry == "GH") {
        this.selected_country = "GH";
      } else {
        this.selected_country = "CI";
      }
      store
        .dispatch("admin/reporting/getFinanceGraph", {
          country: this.selected_country,
        })
        .then((res) => {
          let todayDate = new Date().toDateString();
          res.forEach((element) => {
            this.graphData.push(element.daily_amount);
            this.graphLabels.push(element.day);
            if (new Date(element.day).toDateString() == todayDate) {
              this.amountToday = element.daily_amount;
            }
          });
        });
      store
        .dispatch(
          "admin/reporting/getBundleHistory",

          {
            to: "",
            from: "",
            country: this.selected_country,
          }
        )
        .then((res) => {
          this.tableData = res.splice(0, 10);
        })
        .finally(() => (this.loading = false));
    },
  };
</script>

<style lang="scss" scoped>
  .finance_graph_card {
    background-color: #fff;
    height: 411px;

    .header {
      height: 48px;
      border-bottom: 2px solid #f3f3f4;

      .__header__inner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 32px;
        padding-right: 32px;
      }

      h1 {
        font-family: "Graphik Regular";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        text-transform: capitalize;
        color: #575761;
      }
    }

    .body {
      .__container_text {
        width: 100%;
        height: 361px;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }

      .amount__ {
        align-items: center;

        h2 {
          font-family: "Graphik Regular";
          font-style: normal;
          font-weight: 600;
          font-size: 34px;
          line-height: 37px;
          text-align: center;
          text-transform: capitalize;
          color: #575761;
        }

        .p {
          font-family: "Graphik Regular";
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 13px;
          text-align: center;
          text-transform: capitalize;
          color: #575761;
        }
      }
    }
  }

  .today_amount {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .today_btn {
    width: 110px;
    height: 34px;
    background: #f3f3f4;
    border-radius: 4px;
    outline: none;
    border: none;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #575761;
  }

  .bundle_history {
    margin-top: 17px;
    margin-bottom: 20px;
    margin-bottom: 5rem;
  }

  button {
    float: right;
    outline: none;
    border: none;
    background-color: transparent;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #f7921c;
  }

  .finance-overview {
    overflow-y: scroll;
    height: 80vmin;
  }
</style>
